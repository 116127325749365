import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import './App.css';
import { LandingRoute, LoginRoute } from './config';
import { ErrorBoundary } from './error-page/ErrorBoundary';
import { ErrorPage } from './error-page/ErrorPage';
import { LoginBoundary } from './authentication/LoginBoundary';
import { LoginPage } from './authentication/LoginPage';
import { AuthProvider } from './authentication/useAuth';
import { PrivateRoute } from './authentication/PrivateRoute';

import { LogoutButton } from './authentication/LogoutButton';

import { AuricleLanding } from './auricle/AuricleLanding';
import { AuriclePrivacy } from './auricle/AuriclePrivacy';
import { NutrificientLanding } from './nutrificient/NutrificientLanding';
import { NutrificientPrivacy } from './nutrificient/NutrificientPrivacy';
import { NutrificientTerms } from './nutrificient/NutrificientTerms';


const PageNotFound = () => <ErrorPage id="pageNotFound" />

export function App() {
  return (
    <AuthProvider>
      <ErrorBoundary>
        <Router>
          <LoginBoundary>
            <Routes>
              <Route path='/error/:id' element={<ErrorPage />} />

              <Route path='/auricle' element={<AuricleLanding />} />
              <Route path='/auricle/privacy' element={<AuriclePrivacy />} />
              <Route path='/nutrificient' element={<NutrificientLanding />} />
              <Route path='/nutrificient/privacy' element={<NutrificientPrivacy />} />
              <Route path='/nutrificient/terms' element={<NutrificientTerms />} />

              <Route path={LoginRoute} element={<LoginPage />} />

              <Route path={LandingRoute} element={<PrivateRoute element={
                 <header>
                  <h1>My Application</h1>
                  <LogoutButton />
                </header>
              } />} />
              
              <Route path='*' element={<PageNotFound />} />
            </Routes>
          </LoginBoundary>
        </Router>
      </ErrorBoundary>
    </AuthProvider>
  );
}
